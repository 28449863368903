import React from 'react'

export default function InfoCard({image , title ,tedad, text}) {
  return (
    <div className='flex place-content-center place-items-center bg-[#F7FBFE] p-5 rounded-xl gap-2 w-[220px] md:w-[240px] lg:w-[260px] h-[65px] drop-shadow-[4px_4px_12px_rgba(0,0,0,0.25)] lg:w-[250px] lg:h-[80px]'>
      <img src={image} className='w-12 h-12' alt="" />
      <div className='flex flex-col gap-1'>
        <h3 className='BShiraz sm:text-base font-bold'>{title}</h3>
        <p className=' sm:text-sm text-[rgb(148,148,148)] text-[11px] md:text-[10px] lg:text-xs'><span className=''>{tedad}</span>{text}</p>
      </div>
    </div>
  )
}
