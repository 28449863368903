import React from 'react'
import helper from './../../../assets/video/help.mp4'
import { renderAboutPage } from '../../../Modules/renderAboutPage'
import helpIcon from './../../../assets/images/Mortarboard.png'

export default function HelpStudent() {
    const names=[
        'راهنمای دانشجویان'
    ]
  return (
    <>
    {renderAboutPage(names ,helpIcon ,'راهنمای دانشجویان' )}
    <div className='flex place-content-center place-items-center my-8'>
        <video className=" h-40 md:h-80 rounded-xl" controls>
        <source src={helper} type="video/mp4"/>
        </video>
    </div>
    </>

  )
}
