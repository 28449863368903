import React, { useEffect, useState } from 'react';
import { useSpring, animated, useSprings } from 'react-spring';
import { Link, NavLink } from 'react-router-dom';
import Ellipse from "./../../../../assets/images/Ellipse 1.png";
import StepCard from '../componentCards/StepCard';
import { stepsItem } from '../../method/GetStep';
import { renderTitle } from '../../../../Modules/renderTitle';
import { stepsIcon } from '../../../../Modules/renderArrow';

export default function Steps() {
  const [isVisible, setIsVisible] = useState(false);
  
  useEffect(() => {
    const handleScroll = () => {
      const div = document.getElementById('stepsContainer');
      if (div) {
        const rect = div.getBoundingClientRect();
        const isVisible = rect.top < window.innerHeight && rect.bottom >= 0;
        setIsVisible(isVisible);
      }
    };
  
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);
  
 
  const springs = useSprings(
    stepsItem.length, // تعداد کارت‌ها
    stepsItem.map((step, index) => ({
      from: { opacity: 0, transform: 'translateY(100px)' },
      to: async (next) => {
        await next({ opacity: isVisible ? 1 : 0, transform: isVisible ? 'translateY(0)' : 'translateY(100px)' });
      },
      config: { delay: isVisible ? index * 100 : 0 } 
    }))
  );

  return (
    <>
      <div className='relative'>
        <img src={Ellipse} className='mt-6 w-full h-[100px]' alt="" />
        <div className='absolute top-10 w-full mx-auto'>
          {renderTitle('فرآیند دوره ی کارآموزی', "2xl", "3xl", "5xl")}
        </div>
        {stepsIcon('arrow-right')}
      </div>
      <div className="bg-[#adc8e04d] w-full">
        <div id="stepsContainer" className='relative flex flex-col md:flex-row md:flex-row-reverse md:flex-wrap md:gap-x-3 md:gap-y-16 md:pt-16 xl:max-w-[1200px] mx-auto gap-6 content-start justify-center place-items-center pb-12'>
          {springs.map((props, index) => (
            <animated.div key={index} style={props}>
              <StepCard image={stepsItem[index].image} title={stepsItem[index].title} num={index + 1} />
            </animated.div>
          ))}
        </div>
      </div>
    </>
  );
}
