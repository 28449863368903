import { useEffect, useState } from "react";
import { useCookies } from 'react-cookie';
import axios from 'axios';
import Swal from 'sweetalert2';
import { createFormData } from "../method/formData/renderData";

export const RegisterSubmit = (values) => {
  const [cookie, setCookie] = useCookies(["token"]);
  
  const handleSubmit = async (e) => {
    
    try{ 
      const formData = createFormData(values);
      const response = await axios.post("https://api.sru-intern.ir/api/register", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      const token = response?.data?.authorisation?.token;
      setCookie("token", token, {
        maxAge: 7 * 24 * 60 * 60, // Set your desired expiration time
        path: "/", // Root path
        domain: ".sru-intern.ir", // Replace with your actual domain
        // secure: process.env.NODE_ENV === "production", // Ensures the cookie is only sent over HTTPS in production
        // sameSite: "strict", // Set the sameSite attribute as needed
      });
      const role = response?.data?.user?.role;
      const redirectUrl = getRedirectUrl(role);
      window.location.href = redirectUrl;
      
      Swal.fire({
        position: "top-center",
        icon: "success",
        title: response.data.message,
        showConfirmButton: false,
        timer: 1500
      }); 
    }catch (error) {
      if (error.response && error.response.data.message) {
        Swal.fire({
          icon: "error",
          title: "خطا...",
          html: Object.values(error.response.data.message).map(messages => messages.join('<br>')).join('<br>')
        });
      } 
    }
  };

  const getRedirectUrl = (role) => {
    switch (role) {
      case "student":
        return 'https://student.sru-intern.ir';
      case "industry_supervisor":
        return 'https://supervisor.sru-intern.ir';
      default:
        return '/';
    }
  };

  return handleSubmit;
};


