import * as yup from "yup";

export const supervisorSchema = yup.object().shape({
    firstName: yup.string().min(2,"نام حداقل باید 2 حرف باشد").max(20,"نام حداکثر باید 20 حرف باشد").required("وارد کردن نام الزامیست"),
    LastName: yup.string().min(2,"نام خانوادگی حداقل باید 2 حرف باشد").max(20,"نام خانوادگی حداکثر باید 20 حرف باشد").required("وارد کردن نام خانوادگی الزامیست"),
    nationalId: yup.string().matches(/^\d{10}$/, "کدملی باید 10 رقم باشد").required("وارد کردن کدملی الزامیست"),
    companyName: yup.string().min(2,"نام شرکت حداقل باید 2 حرف باشد").max(20,"نام شرکت حداکثر باید 20 حرف باشد").required("وارد کردن نام شرکت الزامیست"),
    Phone: yup.string().matches(/^09\d{9}$/, "شماره همراه نامعتبر است").required("وارد کردن شماره همراه الزامیست"),    
    Email: yup.string().required("وارد کردن ایمیل الزامیست"),
    password: yup.string().min(8,"رمزعبور حداقل باید 8 کارکتر باشد").max(20,"رمزعبور حداکثر باید 20 کارکتر باشد").matches(/[a-z]+/,"رمزعبور باید شامل حروف a-z باشد").matches(/[A-Z]+/,"رمزعبور باید شامل حروف A-Z  باشد").matches(/\d*/,"رمزعبور باید شامل اعداد باشد").required("رمز عبور الزامیست"),
    confirmPassword:yup.string().oneOf([yup.ref("password")],"تکرار رمز عبور صحیح نیست").required(" تکرار رمز عبور الزامیست")
  });