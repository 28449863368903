import React, { useEffect, useState } from 'react';
import newsIcon from './../../../../assets/images/newsIcon.png';
import { Link } from 'react-router-dom';
import NewsCard from '../componentCards/NewsCard';
import { renderTitle } from '../../../../Modules/renderTitle';
import { getNewsData } from '../../dataContext/getNewsData';
import moment from 'moment';
import 'moment/locale/fa';
import jMoment from 'moment-jalaali';
import { PuffLoader } from 'react-spinners';
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import { Pagination, Autoplay } from 'swiper/modules';
moment.locale('fa');

export default function News() {
  
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setpage] = useState([])

  useEffect(() => {
    getNewsData()
      .then((response) => {
        const newsData = response.data;
        newsData.forEach(news => {
          news.shortCreatedAt = news.created_at.slice(0, 10);
          news.created_at = jMoment(news.shortCreatedAt).format('jYYYY/jMM/jDD');
        });
        setData(newsData);
        setpage(response.meta)
        setLoading(false)
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false)
      });
  }, []);

  return (
    <div className='flex flex-col gap-12 p-2 pb-16'>
        <div className='grid grid-cols-[80%_20%] md:grid-cols-[85%_15%] lg:grid-cols-[90%_10%]'>
          <div className='flex place-content-start place-items-center'>
              <img src={newsIcon} className='w-8 h-8 md:w-10 md:h-10 lg:w-10 lg:h-12' alt="company" />
              {renderTitle('آخرین اخبار','xl','4xl','5xl')}
          </div>
          <Link className='flex place-content-center place-items-center text-[#adb5bd] hover:text-[#ced4da] text-xs' to={`/news`}>
            <p className='text-center'>مشاهده همه</p>
            <span><i className="bi bi-caret-left-fill"></i></span>
          </Link>
        </div>
        {loading?(
          <div className="w-full h-28 grid place-content-center place-items-center">
          <PuffLoader className="h-16 text-[#011C4B]" />
          </div>
        ):(
          <Swiper
    className='w-full'
  // install Swiper modules
    modules={[Pagination, Autoplay]}
    breakpoints={{
      320: {
        slidesPerView: 1,
        spaceBetween: 0
      },
      480: {
        slidesPerView: 1,
        spaceBetween: 0
      },
      640: {
        slidesPerView: 2,
        spaceBetween: 0
      },
      768: {
        slidesPerView: 3,
        spaceBetween: 40
      },
      1280: {
        slidesPerView:4,
        spaceBetween: 30
      }
    }}
    pagination={{ clickable: true }}
    autoplay={{ delay: 2000 }}
    >
      {data.slice(-(page.per_page)).map((news) => (
        <SwiperSlide key={news.id} className='pt-4 pb-10'>
        <Link className="w-20 mx-auto flex place-content-center place-items-center" to={`/news/${news.id}`}>
          <NewsCard image={news.image} title={news.title} text={news.body} date={news.created_at} shortCreatedAt={news.shortCreatedAt} />
        </Link>
      </SwiperSlide>
      
      
            ))}
    </Swiper>
        )}
      
    </div>
  )
}
