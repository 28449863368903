import React from 'react'
import { renderTitle } from '../../../../Modules/renderTitle'

export default function NewsCard({image , title , text , date}) {
  return (
    <div className='bg-[#F7FBFE] w-64 h-80 shadow-md grid grid-rows-[40%_10%_20%_20%] place-content-center place-items-center rounded-xl p-[5%] md:pt-5 pb-0 gap-3'>
        <img src={image} className='rounded-t-xl w-56 h-32 !grayscale-[60%] pt-2' alt="" />
        {renderTitle(title)}
        <p className='line-clamp-3 text-sm text-[#32579b89] px-4'>{text}</p>
        <div className='flex place-content-center place-items-center'>
            <p className='text-[#32579b89] shadow-inner w-64 text-center mt-2 pt-2'>{date}</p>
        </div>
      
    </div>
  )
}
