import info1 from './../../../assets/images/info1.png';
import info2 from './../../../assets/images/info2.png';
import info3 from './../../../assets/images/info3.png';
import info4 from './../../../assets/images/info4.png';
import info5 from './../../../assets/images/info5.png';
import info6 from './../../../assets/images/info6.png';
export const InfoItem=[
   
        {
           "image": info1,
           "title":"دانشجویان دوره",
           "text":" دانشجو ثبت نام شده اند."
        },
        {
           "image": info2,
           "title":" شرکت همکار",
           "text":" شرکت با ما همکاری داشته اند."
        },
        {
           "image": info3,
           "title":"واحد آموزشی ",
           "text":"واحد آموزشی ثبت شده داریم."
        },
        {
           "image": info4,
           "title":"رشته دوره",
           "text":" رشته دوره ثبت شده داریم."
        },
        {
           "image":info5,
           "title":"کل اساتید",
           "text":" استاد ثبت شده داریم."
        },
        {
           "image": info6,
           "title":" کل دانشجویان",
           "text":" دانشجو ثبت شده داریم."
        }
     ]
