import step1 from './../../../assets/images/step1.png'
import step2 from './../../../assets/images/step2.png'
import step3 from './../../../assets/images/step3.png'
import step4 from './../../../assets/images/step4.png'
import step5 from './../../../assets/images/step5.png'
import step6 from './../../../assets/images/step6.png'
import step7 from './../../../assets/images/step7.png'
import step8 from './../../../assets/images/step8.png'
import step9 from './../../../assets/images/step9.png'
import step10 from './../../../assets/images/step10.png'
export const stepsItem=[
        {
           "image": step1,
           "title":"ثبت نام دانشجو"
        },
        {
           "image": step2,
           "title":"تکمیل رزومه"
        },
        {
           "image": step3,
           "title":"ارسال درخواست به شرکت مورد نظر"
        },
        {
           "image": step4,
           "title":"نهایی کردن درخواست توسط دانشجو"
        },
        {
           "image":step5,
           "title":"بررسی و تایید سرپرست کارآموزی و تعیین استاد راهنما"
        },
        {
           "image": step6,
           "title":"اخذ واحد کارآموزی در سامانه گلستان"
        },
        {
           "image": step7,
           "title":"حضور در شرکت و گذراندن دوره"
        },
        {
           "image":step8,
           "title":"تکمیل گزارشات هفتگی و ثبت نمره توسط شرکت"
        },
        {
           "image": step9,
           "title":"ارزیابی دوره توسط کارآموز (دانشجو)"
        },
        {
           "image": step10,
           "title":"اخذ واحد کارآموزی در سامانه گلستان"
        }
     ]
