import React from 'react';
import { BrowserRouter } from "react-router-dom";
import ReactDOM from 'react-dom/client';
import App from './App';
import './../node_modules/tailwindcss/tailwind.css';
import 'flowbite/dist/flowbite.min.js';
import './style/main.css'
import 'material-icons/iconfont/material-icons.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <App />
    </BrowserRouter>
);