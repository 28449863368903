import React, { useEffect, useState } from "react";
import laptop from "./../../assets/images/laptop.png";
import MacBook from "./../../assets/images/MacBook.png";
import rounded from "./../../assets/images/rounded.png";
import { Link, useNavigate } from "react-router-dom";
import { useCookies } from 'react-cookie';
import axios  from 'axios'
import Swal from 'sweetalert2'
import LoginForm from "./components/LoginForm";
import HttpRequest from "../../hooks/useFetch";

function Login() {
  const [username, setuserName] = useState('');
  const [password, setPassword] = useState('');
  const [loginError, setLoginError] = useState("");
  const [cookie, setCookie] = useCookies(["token"]);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (username.length === 0 || password.length === 0) {
        Swal.fire({
          icon: "error",
          title: "خطا...",
          text: "لطفا تمام مقادیر را پر کنید",
        });

        return;
      }
      const response = await HttpRequest().post("https://api.sru-intern.ir/api/login", {
        username,
        password,
      });
      if(response?.status === 200){
        const  token  = response?.data?.authorisation?.token;
        setCookie("token", token, {
          maxAge: 7 * 24 * 60 * 60, // Set your desired expiration time
          path: "/", // Root path
          domain: ".sru-intern.ir", // Replace with your actual domain
          // secure: process.env.NODE_ENV === "production", // Ensures the cookie is only sent over HTTPS in production
          // sameSite: "strict", // Set the sameSite attribute as needed
        });
        Swal.fire({
          position: "top-center",
          icon: "success",
          title: "ورود با موفقیت انجام شد.",
          showConfirmButton: false,
          timer: 1500,
        });
        switch (response?.data?.user?.role) {
          case "student":
            window.location.href='https://student.sru-intern.ir'
            break;
          case "industry_supervisor":
            window.location.href='https://supervisor.sru-intern.ir'
            break;
          case "master":
            window.location.href='https://master.sru-intern.ir'
            break;
          case "admin":
            window.location.href='https://admin.sru-intern.ir/dashboard/'
            break;
          case "mailroom":
            window.location.href='https://mailroom.sru-intern.ir'
            break;
        
          default:
            break;
        }
      }
      else if(response?.status === 400 || response?.status === 401 ){
        setLoginError(response.data.message);
        Swal.fire({
          icon: "error",
          title: "خطا...",
          text: response.data.message,
        });
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        setLoginError(error.response.data.message);
        Swal.fire({
          icon: "error",
          title: "خطا...",
          text: error.response.data.message,
        });
      } /* else {
        setLoginError(error.response.data.error);
        Swal.fire({
          icon: "error",
          title: "خطا...",
          text: loginError,
        });
      } */
    }
  };

  return (
    <div className="w-[100vw] h-[100vh] p-2 bg-[#EAEDF7] flex place-content-center place-items-center">
      <div className=" bg-[#011C4B] w-[90vw] h-[90vh] md:mx-auto flex flex-col md:flex-row place-contewnt-center place-items-center mx-auto md:shadow-xl rounded-xl md:mt-2 xl:max-w-[1200px] md:bg-[#FDFEFF]">
        <div className="relative w-full h-2/6 md:h-full flex flex-col place-content-center place-items-center md:order-2 md:relative md:bg-[#011C4B] md:rounded-r-full">
          <img src={laptop} className="absolute top-[50%] right-[50%] translate-x-1/2 -translate-y-1/2 rounded-t-xl w-48 sm:w-72 md:hidden z-50" alt="" />
          <img src={MacBook} className="hidden absolute top-[50%] right-[40%] translate-x-1/2 -translate-y-1/2 rounded-t-xl w-11/12 md:flex" alt="" />
          <button className="absolute top-0 left-0  w-[80px] h-[40px] justify-center text-xs">
              <Link to="/">
                <div className="!text-[#fbfbfb] flex place-content-center place-items-center gap-2">
                  <h1 className="text-xs BTitr">بازگشت</h1>
                  <span className="font-bold">
                    <i className="bi bi-arrow-left"></i>
                  </span>
      
                </div>
              </Link>
            </button>
            <div className="absolute -bottom-1 -right-0 md:hidden">
              <img src={rounded} className="w-12 h-12" alt="" />
            </div>
        </div>
        <div className="bg-[#FDFEFF] flex flex-col gap-3 place-content-center place-items-center w-full h-4/6 md:h-full md:grid md:grid-rows-3 md:grid-cols-1 md:rounded-l-none md:rounded-r-xl rounded-tl-[3rem] rounded-b-xl py-4">
          <div className="md:w-full md:px-4 md:grid md:grid-cols-2 md:place-content-center md:place-items-center md:place-self-start md:gap-4">
            <div className="hidden md:flex place-content-center place-items-center text-[#011C4B]">
              <span><i className="bi bi-door-open-fill"></i></span>
              <h1 className="BTitr">ورود به حساب کاربری</h1>
            </div>
          </div>
          <LoginForm userName={username} password={password}  setuserName={setuserName} setPassword={setPassword} handleSubmit={handleSubmit}/>
        </div>
      </div>
    </div>
  );
}

export default Login;
