export const Inputs = [
    {
      id: "firstName",
      name: "firstName",
      type: "text",
      placeholder: "نام را وارد کنید",
    },
    {
      id:"LastName",
      name:"LastName",
      type: "text",
      placeholder:"نام خانوادگی را وارد کنید",
    },
    {
      id:"nationalId",
      name:"nationalId",
      type:"text",
      placeholder:"کدملی را به صورت ۱۰ رقمی وارد کنید",
    },
    {
      id:"studentId",
      name:"studentId",
      type:"text",
      placeholder:"کد دانشجویی را وارد کنید",
    },
    {
        id: "Phone",
        name: "Phone",
        type: "text", // تغییر نوع ورودی به text
        placeholder: "شماره تلفن همراه خود را وارد کنید",
      }
      ,
    {
      id:"Email",
      name:"Email",
      type:"Email",
      placeholder:"ایمیل را وارد کنید",
    }
  ];