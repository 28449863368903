import React, { useEffect, useState } from 'react';
import { GetSingleNews } from '../../dataContext/getNewsData';
import moment from 'moment';
import 'moment/locale/fa';
import jMoment from 'moment-jalaali';
import { PuffLoader } from 'react-spinners';
import { renderAboutPage } from '../../../../Modules/renderAboutPage';
import newsIcon from './../../../../assets/images/newsIcon.png';
import { useParams } from 'react-router-dom';

export default function SingleNews() {
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [notFound, setNotFound] = useState(false);
  const [Error, setError] = useState(false)
  
  useEffect(() => {
    if (!isNaN(parseInt(id))) {
      GetSingleNews({id})
        .then((response) => {
          if(response.error){
            setError(response.error)
          }
          const newData = response.data;
          newData.shortCreatedAt = newData.created_at.slice(0, 10);
          newData.created_at = jMoment(newData.shortCreatedAt).format('jYYYY/jMM/jDD');
          setData(newData);
          setLoading(false);
          setNotFound(false);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          // Set notFound state to true when news is not found
          setNotFound(true);
          setLoading(false);
        });
    } else {
      // Handle the case where id is not a valid integer
      console.error("Invalid id provided:", id);
      setNotFound(true);
      setLoading(false);
    }
  }, [id]);

  const names = ['همه اخبار', data.title];

  return (
    <>
      {renderAboutPage(names, newsIcon, data.title)}
      {loading ? (
        <div className="w-full h-28 grid place-content-center place-items-center">
          <PuffLoader className="h-16 text-[#011C4B]" />
        </div>
      ) : notFound ? ( // Display message when news is not found
        <div className="BTitr font-bold text-center text-[#215AB5] text-lg p-5">{Error ? Error : 'خبری یافت نشد!'}</div>
      ) : (
        <div className='p-5 flex flex-col gap-3 place-content-center place-items-center'>
          <img src={data.image} className='w-3/4 max-w-[550px] shadow-inner h-auto !grayscale-[60%] mx-auto' alt="" />
          <div className='flex flex-col place-content-center place-items-center gap-3 md:grid md:grid-cols-2'>
            <h1 className='BShiraz text-2xl font-bold text-[#011C4B] justify-self-start'>{data.title}</h1>
            <p className='BShiraz text-[#8d99ae] justify-self-end'>{data.created_at}</p>
          </div>
          <p className='text-sm sm:text-base md:text-base text-[#011c4ba6] px-2 max-w-[600px] text-center'>{data.body}</p>
          <div></div>
        </div>
      )}
    </>
  );
}
