import React, { useEffect, useState } from "react";
import info2 from "./../../../../assets/images/info2.png";
import { Link } from "react-router-dom";
import { renderTitle } from "../../../../Modules/renderTitle";
import { getCompanyData } from "../../dataContext/getCompanyData";
import { PuffLoader } from "react-spinners";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, A11y } from "swiper/modules";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import { NewsSwiperBtnNext, NewsSwiperBtnPrev } from "../swiperButton";

export default function Company() {
  const [activeIndex, setActiveIndex] = useState(6);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getCompanyData()
      .then((data) => {
        setData(data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  }, []);
  return (
    <div className="flex flex-col gap-12 bg-[#adc8e04d] p-2 pb-16">
      <div className="grid grid-cols-[80%_20%] md:grid-cols-[90%_10%]">
        <div className="flex place-content-start place-items-center">
          <img
            src={info2}
            className="w-8 h-8 md:w-10 md:h-10 lg:w-10 lg:h-12"
            alt="company"
          />
          {renderTitle("شرکت های همکار", "xl", "4xl", "5xl")}
        </div>
        <Link
          to="./companies"
          className="flex place-content-center place-items-center text-[#adb5bd] hover:text-[#ced4da] text-xs"
        >
          <p className="text-center">مشاهده همه</p>
          <span>
            <i className="bi bi-caret-left-fill"></i>
          </span>
        </Link>
      </div>
      <div className="grid grid-cols-[10%_80%_10%] place-content-center place-items-center">
        <div>
          <NewsSwiperBtnNext swiperClass="News-swiper-next-btn" />
        </div>
        {loading ? (
          <div className="bg-[#E9F1F9] w-full h-28 grid place-content-center place-items-center">
            <PuffLoader className="h-16 text-[#011C4B]" />
          </div>
        ) : (
          <div className="w-full relative !flex !place-content-center !place-items-center">
            <Swiper
              className="w-full mx-auto"
              // install Swiper modules
              modules={[Navigation, A11y]}
              loop
              navigation={{
                nextEl: ".News-swiper-next-btn",
                prevEl: ".News-swiper-prev-btn",
              }}
              breakpoints={{
                320: {
                  slidesPerView: 1,
                  spaceBetween: 0,
                },
                480: {
                  slidesPerView: 3,
                  spaceBetween: 30,
                },
                640: {
                  slidesPerView: 4,
                  spaceBetween: 20,
                },
              }}
              onSlideChange={(index) => {
                if (index.realIndex + 1 === data.length) {
                  setActiveIndex(0);
                } else {
                  setActiveIndex(index.realIndex + 1);
                }
              }}
              allowTouchMove
            >
              {data.map((logo, logoNum) => (
                <SwiperSlide key={logoNum}>
                <Link className="w-20 mx-auto flex place-content-center place-items-center" to="">
                  <img
                    src={logo.company_image}
                    className="w-20 grayscale hover:grayscale-0 hover:scale-110 ease-in duration-300"
                    alt=""
                  />
                </Link>
              </SwiperSlide>
              
              ))}
            </Swiper>
          </div>
        )}

        <div>
          <NewsSwiperBtnPrev swiperClass="News-swiper-prev-btn" />
        </div>
      </div>
    </div>
  );
}
