import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import {stepsIcon } from '../../../../Modules/renderArrow';
export default function StepCard({image , title , num}){

return(
 <>
    <div className='relative w-[130px] h-[130px] lg:w-[180px] lg:h-[180px] xl:w-[200px] xl:h-[200px] bg-[#F7FAFF] flex flex-col place-content-center place-items-center p-3 rounded-xl'>
        <div className='absolute top-0 -right-10 w-[1px] h-[160px] md:w-[145px] lg:w-[195px] xl:w-[210px] md:h-[1px] md:-top-6 md:right-0 bg-[#F7FAFF]'></div>
        <img src={image} className='w-[60px] h-[60px] lg:w-20 lg:h-20' alt="" />
        <h1 className='text-xs lg:text-sm text-center text-[#1E1E1E] lg:mt-2'>{title}</h1>
        <div className="absolute bottom-0 right-[50%] translate-x-1/2 md:hidden flex place-content-center place-items-center">
            {num !== 10 ? stepsIcon('arrow-down') : stepsIcon('check-lg')}
            
        </div>
        <div className="hidden absolute bottom-0 right-[50%] translate-x-1/2 md:block">
            {num !== 10 ? stepsIcon('arrow-right') : stepsIcon('check-lg')}
            
        </div>
        <div className='flex place-content-center place-items-center gap-2 md:gap-3 absolute top-[50%] -right-10 md:-top-6 md:right-[50%] translate-x-1/2 -translate-y-1/2'>
        <p className='BShiraz font-bold'> {num} </p>
            <div className=" translate-x-1/2 bg-[#ADC8E0] rounded-full w-4 h-4 flex place-content-center place-items-center">
                <div className={`animate-[ping_1s_linear_infinite] bg-[#215AB5] rounded-full w-2 h-2`}></div>
            </div>
        </div>
    </div>
 </>

)

}
