import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { PuffLoader } from 'react-spinners';
import { getCompanyData, getNewsData } from '../../dataContext/getCompanyData';
import { renderAboutPage } from '../../../../Modules/renderAboutPage';
import companyIcon from './../../../../assets/images/info2.png'
import CompanyCard from '../componentCards/companyCard';
export default function AllCompanies() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
      getCompanyData()
      .then((response) => {
        setData(response.data);
        setLoading(false)
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false)
      });
  }, []);
  const names=[
    'شرکت های همکار'
  ]
  return (
    <div className='flex flex-col gap-12 p-2 pb-16'>
        {renderAboutPage(names,companyIcon,'شرکت های همکار')}
        {loading?(
          <div className="w-full h-28 grid place-content-center place-items-center">
          <PuffLoader className="h-16 text-[#011C4B]" />
          </div>
        ):(
          <div className='flex flex-col md:flex-row md:flex-wrap gap-8 place-content-center place-items-center'>
              {data.map((company,companyId) => (
                  <Link to={`/companies/${companyId+1}`}>
                    <CompanyCard image={company.company_image} name={company.name}/>
                  </Link>
              ))}
          </div>
        )}
      
    </div>
  )
}
