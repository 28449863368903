import { renderTitle } from "./renderTitle";

export function renderAboutPage(name , icon , title) {
    return (
        <>
        <div className="flex flex-col gap-4 mt-2 p-2">
          <div className='flex gap-1 text-[#011c4b6d] text-xs font-bold'>
              <i className="bi bi-house"></i>
              <p>صفحه اصلی</p>
              {name.map((classs, index) => (
                  <div className="flex place-content-center place-tems-center">
                    <span className='animate-pulse'><i className="bi bi-caret-left-fill"></i></span>
                    <p key={index}>{classs}</p>
                  </div>
              ))}

          </div>
          <div className='grid grid-cols-[80%_20%] md:grid-cols-[85%_15%] lg:grid-cols-[90%_10%] border-b-2 pb-2'>
            <div className='flex place-content-start place-items-center'>
                <img src={icon} className='w-8 h-8 md:w-10 md:h-10 lg:w-10 lg:h-12' alt="company" />
                {renderTitle(title,'lg','2xl','3xl')}
            </div>
          </div>
        </div>
      
      </>
    )
  }