import * as yup from "yup";

export const exportSchema = yup.object().shape({
    firstName: yup.string().min(2,"نام حداقل باید 2 حرف باشد").max(20,"نام حداکثر باید 20 حرف باشد").required("وارد کردن نام الزامیست"),
    LastName: yup.string().min(2,"نام خانوادگی حداقل باید 2 حرف باشد").max(20,"نام خانوادگی حداکثر باید 20 حرف باشد").required("وارد کردن نام خانوادگی الزامیست"),
    nationalId: yup
    .string()
    .matches(/^\d{10}$/, "کدملی باید 10 رقم باشد")
    .required("وارد کردن کدملی الزامیست"),
    studentId: yup
      .string()
      .matches(/^\d{10}$/, "شماره دانشجویی باید 10 رقمی باشد")
      .required("وارد کردن شماره دانشجویی الزامیست"),
      Phone: yup.string()
      .matches(/^09\d{9}$/, "شماره همراه نامعتبر است")
      .required("وارد کردن شماره همراه الزامیست"),    
    Email: yup.string().required("وارد کردن ایمیل الزامیست"),
  });