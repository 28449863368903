import arrowCopmany from './../../../../assets/images/ArrowIcon.png'

export function NewsSwiperBtnPrev({ swiperClass }) {
  return (
    <button
      className={`${swiperClass} bottom-10 right-1/2 z-10 flex items-center justify-center h-6 sm:h-8 w-6 sm:w-8 rounded-xl active:scale-90 duration-200 `}
    >
       <img src={arrowCopmany} className="w-6 sm:w-8 rotate-180 text-[#012a4a]"/>
    </button>
  );
}

export function NewsSwiperBtnNext({ swiperClass }) {
  return (
    <button
      className={` ${swiperClass} bottom-10 left-1/2 z-10 flex items-center justify-center h-6 sm:h-8 w-6 sm:w-8 rounded-xl active:scale-90 duration-200 `}
    >
        <img src={arrowCopmany} className="w-6 sm:w-8 text-[#012a4a]"/>
    </button>
  );
}