
import HttpRequest from '../../../hooks/useFetch'

export async function getCompanyData() {
  try {
    const response = await HttpRequest().get(`home/companies`)
    if (response.status === 200) {
      return response.data
    }

    return Promise.reject(response.data)
  } catch (error) {
    return Promise.reject(error)
  }
}
/* export async function getSingleCompany({ id }) {
    try {
    const response = await HttpRequest().get(`home/companies/${id}`);
    if (response.status === 200) {
        return response.data
      }
  
      return Promise.reject(response.data)
    } catch (error) {
      return Promise.reject(error)
    }
  } */
