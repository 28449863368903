import React, { useEffect, useState } from "react";
import attach from "./../../../../assets/images/attach.png";
import { InfoItem } from "../../method/GetInfo";
import InfoCard from "../componentCards/InfoCard";
import { getHomeData } from "../../dataContext/getHomeData.api";
import { renderTitle } from "../../../../Modules/renderTitle";
import { PuffLoader } from "react-spinners";
import { useSprings, animated } from 'react-spring';

export default function Info() {
  const [data, setData] = useState("");
  const [loading, setLoading] = useState(true);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    getHomeData()
      .then((data) => {
        setData(Object.values(data));
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });

    const handleScroll = () => {
     
      const div = document.getElementById('infoContainer');
      if(div){
        const rect = div.getBoundingClientRect();
        const isVisible = rect.top < window.innerHeight && rect.bottom >= 0;
        setIsVisible(isVisible);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const springs = useSprings(
    InfoItem.length,
    InfoItem.map((info, index) => ({
      
      from: { opacity: 0, transform: 'translateX(100px)' },
      to: async (next) => {
        await next({ opacity: isVisible ? 1 : 0, transform: isVisible ? 'translateX(0)' : 'translateX(100px)' });
      },
      config: { delay: isVisible ? index * 1000 : 0 } 
    }))
  );

  return (
    <>
      <div className="bg-[#adc8e04d] w-full">
        <div className="bg-[#f7fbfe61] w-full pt-6">
          <div className="flex flex-col place-content-center place-items-center">
            <div className="relative w-48 lg:w-96">
              {renderTitle("گزارش سامانه کارآموزی", "3xl", "4xl", "5xl")}
              <img
                src={attach}
                className="w-12 absolute -right-2 top-[50%] translate-x-1/2 -translate-y-1/2"
                alt=""
              />
            </div>
            {loading ? (
              <div className="bg-[#E9F1F9] w-full h-28 grid place-content-center place-items-center">
                  <PuffLoader className="h-16 text-[#011C4B]" />
              </div>
            ) : (
              <div id="infoContainer" className="flex flex-col place-content-center place-items-center gap-6 mt-8 md:grid md:grid-cols-3 lg:gap-12">
                {springs.map((props, index) => (
                  <animated.div key={index} style={props}>
                    <InfoCard
                      image={InfoItem[index].image}
                      title={InfoItem[index].title}
                      text={InfoItem[index].text}
                      tedad={data[index]}
                    />
                  </animated.div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="w-full h-[50px] bg-[#F7FBFE] -mt-8"></div>
    </>
  );
}
