import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import HeaderImg from './../../../../assets/images/headerImg.png';
import searchIcon from './../../../../assets/images/searchIcon.png'


export default function Header(){

return(
 <>
 <div className='mx-auto flex flex-col md:grid md:grid-cols-2 md:py-12 place-content-center place-items-center'>
    <img src={HeaderImg} className='h-[205px] md:h-[250px] lg:h-auto lg:w-[500px] w-auto mx-auto md:order-2' alt="" />
    <div className='flex flex-col place-content-center place-items-center md:justify-start md:place-items-start md:place-content-center md:mr-6 lg:mr-12 gap-4 md:gap-8 md:order-1'>
        <div className='md:flex md:place-content-center md:place-items-center md:justify-self-start'>
            <img src={searchIcon} className='hidden md:block md:w-16 md:h-16 animate-bounce' alt="" />
            <h1 className='BTitr text-[#011C4B] mx-auto text-3xl lg:text-5xl text-center md:text-right max-w-72 lg:max-w-[500px]'>سامانه کارآموزی دانشگاه شهید رجایی تهران</h1>
        </div>
        <p className='w-10/12 text-center md:text-right text-sm md:text-base lg:text-lg text-[#6f88b9]'>کارآموزی در دانشگاه شهید رجایی یک فرصت عالی برای دانشجویان است تا در محیطی واقعی و در زمینه‌های مختلف تجربه کاری کسب کنند. این برنامه‌ها به دانشجویان این امکان را می‌دهند تا علاوه بر دریافت دانش تئوریک، با مسائل و چالش‌های عملی در زمینه‌های مختلف مواجه شوند.</p>
    </div>

 </div>
 </>

)

}
