import React from 'react'
import { renderTitle } from '../../../../Modules/renderTitle'

export default function CompanyCard({image , name }) {
  return (
    <div className='grid grid-rows-[65%_35%] place-content-center place-items-center bg-[#F7FBFE] rounded-xl gap-2 w-[220px] h-[220px] shadow-inner border'>
      <img src={image} className='place-self-center w-20 grayscale-[50%] hover:grayscale-0 ease-in duration-300 hover:w-24 md:w-24 md:hover:w-28' alt="" />
      <div className='w-[220px] h-20 shadow-inner flex place-content-center place-items-center text-center'><p className='BShiraz text-[#adb5bd] font-bold text-base'>{name}</p></div>
    </div>
  )
}
