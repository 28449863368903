import React, { useEffect, useState } from "react";
import RegisterForm from "./components/RegisterForm";
import HeaderRegister from "./components/HeaderRegister";
import { RegisterSubmit } from "./dataContext/RegisterSubmit";

function Register() {
  const [values, setValues] = useState({
    firstName: "",
    LastName: "",
    nationalId: "",
    studentId: "",
    Phone: "",
    Email: ""
  });
  const handleSubmit = RegisterSubmit(values);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value
    });
  };

  return (
    <div className="w-full md:h-[100vh] p-2 bg-[#EAEDF7] flex place-content-center place-items-center">
      <div className=" bg-[#011C4B] w-[90vw] md:h-[98vh] md:mx-auto flex flex-col md:flex-row place-contewnt-center place-items-center mx-auto md:shadow-xl rounded-xl xl:max-w-[1200px] md:bg-[#FDFEFF]">
        <HeaderRegister />
        <div className="bg-[#FDFEFF] flex flex-col gap-3 place-content-center place-items-center w-full h-4/6 md:h-full md:grid md:grid-rows-3 md:grid-cols-1 md:rounded-l-none md:rounded-r-xl rounded-tl-[3rem] rounded-b-xl py-4">
          <div className="md:w-full md:px-4 md:grid md:grid-cols-2 md:place-content-start md:place-items-start md:place-self-start md:gap-4">
            <div className="hidden md:flex place-content-start place-items-start text-[#011C4B]">
              <span>
                <i className="bi bi-door-open-fill"></i>
              </span>
              <h1 className="BTitr">ثبت نام کاربر</h1>
            </div>
          </div>
          <RegisterForm
            values={values}
            handleInputChange={handleInputChange}
            handleSubmit={handleSubmit}
          />
        </div>
      </div>
    </div>
  );
}

export default Register;
