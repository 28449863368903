import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import {CookiesProvider,useCookies} from 'react-cookie'
import Home from './pages/home';
import Register from './pages/register/Signup';
import Login from './pages/login';
import Navbar from './components/navbar';
import Footer from './components/Footer';
import SingleNews from './pages/home/components/SinglePages/SingleNews';
import AllCompanies from './pages/home/components/MainPage/AllCompanies';
import AllNews from './pages/home/components/MainPage/AllNews';
import HelpCompany from './pages/home/components/HelpCompany';
import HelpStudent from './pages/home/components/HelpStudent';
import RegisterSupervisor from './pages/register/SignupSupervisor';

function App() {
  const [cookies] = useCookies(["token"]);
/*   const location = useLocation();
  const navigate = useNavigate(); */
  let location = useLocation();
  const cookie=cookies.token;
/*   useEffect(() => {
    window.scrollTo(0, 0); 
  }, [location.pathname]);
    useEffect(() => {
      if(location.pathname === "/adminPannel"){
        if(cookie === undefined){
          navigate("/admin")
        }
      }
    }, [location.pathname, navigate, cookie]); */

  return (
    <>
      {location.pathname !== '/login' && location.pathname!=='/signup' && <Navbar/>}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/news" element={<AllNews />} />
        <Route path="/news/:id" element={<SingleNews />} />
        <Route path="/companies" element={<AllCompanies />} />
        <Route path="/help_company" element={<HelpCompany />} />
        <Route path="/help_student" element={<HelpStudent />} />
        <Route path="/login" element={<Login/>} />
        <Route path="/signup" element={<Register/>} />
      </Routes>
      {location.pathname !== '/login' && location.pathname!=='/signup' && <Footer/>}
      </>
  );
}

export default App;
