export const createFormData = (values) => {
  const { firstName, LastName, nationalId, studentId, Phone, Email } = values;
  const formData = new FormData();
  formData.append("first_name", firstName);
  formData.append("last_name", LastName);
  formData.append("national_code", nationalId);
  formData.append("student_number", studentId);
  formData.append("phone_number", Phone);
  formData.append("email", Email);
  return formData;
};