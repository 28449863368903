import React from 'react'
import historyImg from './../../../../assets/images/historyImg.png'
import { renderTitle } from '../../../../Modules/renderTitle';
import { historyIcon } from '../../../../Modules/renderhistoryIcon';

export default function History() {
  return (
    <div className='flex flex-col gap-8 my-12'>
        <div className='flex place-content-center place-items-center'>
            {historyIcon('chevron-right')}
            {renderTitle('تاریخچه سامانه کارآموزی','2xl','4xl','5xl')}
            {historyIcon('pen-fill')}
            {historyIcon('chevron-left')}
        </div>
        <div className='md:flex max-w-[1000px] mx-auto [place-content-center place-items-center'>
            <p className='text-[#33363F] text-md px-4 w-10/12 mx-auto'>تاریخچه کارآموزی در دانشگاه شهید رجایی نشان دهنده تلاش‌های مداوم این دانشگاه برای ایجاد فرصت‌های عملی و تجربی برای دانشجویان است. کارآموزی یکی از ابزارهای مهمی است که این دانشگاه از آن برای ارتقاء و توسعه مهارت‌ها و دانش دانشجویان بهره می‌برد. این دانشگاه از سال‌ها پیش به منظور تسهیل و ترویج فرصت‌های کارآموزی در زمینه‌های مختلف، ارتباطات قوی و پایداری با صنایع، شرکت‌ها و سازمان‌های مختلف ایجاد کرده است. این ارتباطات فرصت‌های بیشتری را برای دانشجویان فراهم می‌کند تا با محیط کاری واقعی آشنا شوند و تجربه عملی در زمینه‌های مرتبط با رشته‌های تحصیلی خود کسب کنند. تلاش‌های پیوسته این دانشگاه در جهت ارتقاء برنامه‌های کارآموزی، منجر به ایجاد فرصت‌های متنوع و گسترده‌ای برای دانشجویان شده است. این برنامه‌ها از مقاطع مختلف تحصیلی پشتیبانی می‌کنند و به دانشجویان امکان می‌دهند تا تجربه‌های ارزشمندی را در زمینه‌های مختلف از جمله علوم پایه، مهندسی، علوم انسانی، علوم اجتماعی و... کسب کنند.</p>
            <img src={historyImg} alt="historyImg" className='hidden md:block md:w-80 lg:w-[600px] md:text-base lg:text-lg' />
        </div>
    </div>
  )
}
