import React from 'react'
import Logo from './../assets/images/logoSru.png'
import { renderTitle } from '../Modules/renderTitle'
import { renderFooterText } from '../Modules/renderFooterText'
import { renderFooterTitle } from '../Modules/renderFooterTitle'
import { PiPhoneCallBold } from "react-icons/pi"
import { MdOutlineAttachEmail  } from "react-icons/md";
import { HiOutlineLocationMarker } from "react-icons/hi";


export default function Footer() {
  return (
    <div>
    <div className='bg-[#EAEDF7] p-3 flex flex-col gap-3'>
        <div className='flex gap-2'>
            <img src={Logo} className='w-8 h-8' alt="logo" />
            {renderTitle('سامانه کارآموزی دانشگاه شهید رجایی تهران')}
        </div>
        <div className='flex flex-col gap-3 md:grid md:grid-cols-3'>
            <div className='flex flex-col gap-2'>
                {renderFooterTitle('درباره ما')}
                <div className='md:max-w-64 lg:w-auto'>
                    {renderFooterText('کارآموزی در دانشگاه شهید رجایی از طریق همکاری با صنایع، شرکت‌ها، و سازمان‌های مختلف به دانشجویان فرصت می‌دهد تا مهارت‌ها و دانش‌های خود را در زمینه‌های مختلف ارتقا دهند. این تجربه می‌تواند فرصتی برای شناخت بهتر محیط کاری و برقراری ارتباط با حوزه‌های مختلف صنعت و علمیه باشد.')}
                </div>
            </div>
            <div className='flex flex-col gap-2'>
                {renderFooterTitle('راه های ارتباطی')}
                <div className='flex gap-1 justify-content-center justify-items-center'>
                    <PiPhoneCallBold  className='text-[#011C4B]' />
                    {renderFooterText('تماس با ما:021-22970060 ')}
                </div>
                <div className='flex gap-1 justify-content-center justify-items-center'>
                    <MdOutlineAttachEmail  className='text-[#011C4B]' />
                    {renderFooterText('ایمیل های ارتباطی:info@sru.ac.ir')}
                </div>
                <div className='flex gap-1 justify-content-center justify-items-center'>
                    <HiOutlineLocationMarker className='text-[#011C4B]'/>
                    {renderFooterText('آدرس:تهران- لويزان- خيابان شهيد شعبانلو- دانشگاه تربيت دبير شهيد رجائی ')}
                    
                </div>
            </div>
            <div className='flex flex-col gap-2'>
                    {renderFooterTitle('دسترسی سریع')}
                    <div className='grid grid-cols-2 gap-2 md:flex md:flex-col'>
                        {renderFooterText('پورتال جامع آموزشی و پژوهشی' )}
                        {renderFooterText('سامانه آموزش مجازی')}
                        {renderFooterText('سامانه کتابخانه')}
                        {renderFooterText('اتوماسیون غذا')}
                    </div>
                </div>
        </div>
      
    </div>
    <div className='w-full h-[2px] bg-[#ced4da]'></div>
        <div className='bg-[#EAEDF7] shadow-inner p-2'>
        <p className='BShiraz text-[0.5rem] text-center md:text-sm'>کلیه حقوق و اطلاعات مربوط به وب‌سایت متعلق به دانشگاه تربیت دبیر شهید رجایی تهران می‌باشد. </p>
        </div>
    </div>
  )
}
