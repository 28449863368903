import React, { useState } from 'react';
import Logo from './../assets/images/logoSru.png';
import MoreIcon from './../assets/images/moreIcon.png';
import LoginIcon from './../assets/images/loginIcon.png';
import LoginMDIcon from './../assets/images/loginMDIcon.png';
import HomeIcon from './../assets/images/Home.png';
import PaperIcon from './../assets/images/Paper.png';
import GroupIcon from './../assets/images/Group.png';
import MortarboardIcon from './../assets/images/Mortarboard.png';
import PointersIcon from './../assets/images/Pointers_duotone.png';
import { Link, NavLink } from 'react-router-dom';

const Navbar = () => {
  const [openNav, setOpenNav] = useState(false);

  const toggleNav = () => {
    setOpenNav(!openNav);
  };

  return (
    <>
      <nav className="fixed z-[9999] w-full right-0 top-0 rtl bg-[#F7FBFE] rounded-b-[16px] shadow-xl shadow-[#e9e9e9] h-[53px] p-2">
        <div className="flex items-center justify-between">
          <NavLink to="/" className="flex items-center space-x-3 rtl:space-x-reverse">
            <img src={Logo} className="h-[40px]" alt="SRU Logo" />
          </NavLink>
          <div className='lg:hidden'>
            <button className='inline-flex items-center w-[40px] h-[40px] justify-center rounded-lg md:hidden lg:hidden'>
              <Link to={`./login`}><img src={LoginIcon} alt="Login" /></Link>
            </button>
            <button className='hidden md:inline-flex items-center w-[144px] h-[36px] justify-center rounded-lg '>
              <Link to={`./login`}><img src={LoginMDIcon} alt="Login" /></Link>
            </button>
            <button type="button" className="inline-flex items-center w-[40px] h-[40px] justify-center rounded-lg lg:hidden" onClick={toggleNav}>
              <span className="sr-only">Toggle navigation</span>
              <img src={MoreIcon} alt="More" />
            </button>
          </div>
          <div className={`${openNav ? 'fixed top-10 right-0' : 'hidden'} w-full lg:block lg:w-auto`} id="navbar-default">
            <ul className="w-3/4 lg:w-full lg:h-auto lg:p-0 lg:mt-0 bg-[#F7FBFE] lg:bg-transparent lg:border-none h-[90vh] BTitr font-medium flex flex-col p-4 mt-4 gap-4 text-[#6f88b9] border border-gray-100 rounded-lg lg:flex-row rtl:space-x-reverse">
              <NavItem to="./" icon={HomeIcon} text="صفحه اصلی" />
              <NavItem to="./news" icon={PaperIcon} text="اخبار و اطلاعیه ها" />
              <NavItem to="./companies" icon={GroupIcon} text="شرکت های همکار" />
              <NavItem to="./help_student" icon={MortarboardIcon} text="راهنمای دانشجویان" />
              <NavItem to="./help_company" icon={PointersIcon} text="راهنمای شرکت ها" />
            </ul>
          </div>
          <button className='hidden lg:inline-flex items-center w-[144px] h-[36px] justify-center rounded-lg '>
            <Link to={`./login`}><img src={LoginMDIcon} alt="Login" /></Link>
          </button>
        </div>
      </nav>
      <div className='mb-16'></div>
    </>
  );
};

const NavItem = ({ to, icon, text }) => {
  return (
    <li className='flex hover:bg-gray-100 hover:rounded-xl lg:place-content-center lg:place-items-center'>
      <img src={icon} className='w-[32px] h-[32px]' alt="" />
      <NavLink to={to} className="block lg:text-xs xl:text-base py-2 px-3 lg:ml-3 text-[#b7c3dc] rounded md:bg-transparent" aria-current="page">{text}</NavLink>
    </li>
  );
};

export default Navbar;
