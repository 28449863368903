import HttpRequest from '../../../hooks/useFetch'

export async function getNewsData() {
  try {
    const response = await HttpRequest().get(`home/news?`)
    if (response.status === 200) {
      return response.data
    }

    return Promise.reject(response.data)
  } catch (error) {
    return Promise.reject(error)
  }
}
export async function GetSingleNews({ id }) {
  try {
    const response = await HttpRequest().get(`home/news/${id}`);
    if (response.status === 200) {
      return response.data
    }

    return Promise.reject(response.data)
  } catch (error) {
    return Promise.reject(error)
  }
}
