import axiosInstance from './../dataContext/axiosInstance.helper';
const HttpRequest=()=>{
  axiosInstance.interceptors.request.use(
    function (config) {
      return config
    },
    function (err) {
      return err
    }
  )
  axiosInstance.interceptors.response.use(
    function (response) {
      return response
    },
    function (error) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      if (!error.response) {
        // toast.error('Network Error', {
        //   toastId: 'id-network-error'
        // })
      } else if (error.response.status === 500) {
        // toast.error('500 Internet server Error', {
        //   toastId: 'id-code-500'
        // })
      }

      return error.response
    }
  )
  return axiosInstance
}
export default HttpRequest