
import HttpRequest from '../../../hooks/useFetch'

export async function getHomeData() {
  try {
    const response = await HttpRequest().get(`home/statics`)
    if (response.status === 200) {
      return response.data
    }

    return Promise.reject(response.data)
  } catch (error) {
    return Promise.reject(error)
  }
}
