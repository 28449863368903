import Company from "./components/index/Company";
import History from "./components/index/History";
import Info from "./components/index/Info";
import News from "./components/index/News";
import Steps from "./components/index/Steps";
import Header from "./components/index/Header";


function Home() {
  return (
    <>
       <Header />
      <Steps/>
      <Info />
      <History/>
      <Company />
      <News/>
    </>
  );
}

export default Home;