import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import newsIcon from './../../../../assets/images/newsIcon.png';
import { Link } from 'react-router-dom';
import NewsCard from '../componentCards/NewsCard';
import moment from 'moment';
import 'moment/locale/fa';
import jMoment from 'moment-jalaali';
import { PuffLoader } from 'react-spinners';
import { getNewsData } from '../../dataContext/getNewsData';
import { renderAboutPage } from '../../../../Modules/renderAboutPage';
moment.locale('fa');

export default function AllNews() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize,setPageSize] = useState("");
  const [totalCount, setTotalCount] = useState("")
  const [Pages, setPages] = useState("")
  
  useEffect(() => {
      getNewsData()
      .then((response) => {
        const newsData = response.data;
        newsData.forEach(news => {
          news.shortCreatedAt = news.created_at.slice(0, 10);
          news.created_at = jMoment(news.shortCreatedAt).format('jYYYY/jMM/jDD');
        });
        const InfoPages=response.meta;
        setPageSize(InfoPages.per_page)
        setPages(InfoPages.total_pages)
        setTotalCount(InfoPages.total_records)
        setData(newsData);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  }, [totalCount]);
  const names=['همه اخبار'];
  const pageCount=Math.ceil(totalCount/pageSize);

  const pageVisited=currentPage* pageSize;
  const displayUsers=data.slice(pageVisited,pageVisited+pageSize)
  .map((news) => {
    return(
      <Link to={`/news/${news.id}`}>
      <NewsCard key={news.id} image={news.image} title={news.title} text={news.body} date={news.created_at} shortCreatedAt={news.shortCreatedAt} />
    </Link>
    );
  });
  const changePage=({selected})=>{
    setCurrentPage(selected);
  }

  return (
    <div className='flex flex-col gap-12 p-2 pb-16'>
        {renderAboutPage(names,newsIcon,'آخرین اطلاع رسانی ها')}
        {loading ? (
          <div className="w-full h-28 grid place-content-center place-items-center">
            <PuffLoader className="h-16 text-[#011C4B]" />
          </div>
        ) : (
          <>
            {data.length === 0 ? (
              <p className="text-center text-[#011C4B] text-lg">خبری برای نمایش وجود ندارد.</p>
            ) : (
              <div className='flex flex-col md:flex-wrap gap-8 place-content-center place-items-center px-2'>
                  <div className='flex flex-col md:flex-row md:flex-wrap gap-8 place-content-center place-items-center'>{displayUsers}</div>
                  <ReactPaginate
                  previousLabel={'قبلی'}
                  nextLabel={'بعدی'}
                  pageCount={pageCount}
                  onPageChange={changePage}
                  containerClassName={"inline-flex -space-x-px text-sm"}
                  previousLinkClassName={"flex items-center justify-center px-3 h-8 ms-0 leading-tight text-gray-500 bg-white border border-e-0 border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700"}
                  nextLinkClassName={"flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-e-lg hover:bg-gray-100 hover:text-gray-700"}
                  disabledClassName={pageCount>1?`flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white`: ""}
                  activeClassName={"flex items-center justify-center px-3 h-8 text-blue-600 border border-gray-300 bg-blue-50 hover:bg-blue-100 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white"}
                  />
              </div>
            )}
          </>
        )}
      
    </div>
  );
}
