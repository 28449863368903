import React from "react";
import { Link } from "react-router-dom";
import { Inputs } from "../method/inputs/InputItems";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { exportSchema } from "../method/schema/exportSchema";

export default function RegisterForm(props) {
const schema=exportSchema;
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const onSubmit = handleSubmit(props.handleSubmit);
  return (
    <form onSubmit={onSubmit} className="w-full" action="#">
      <div className="flex flex-col gap-3 md:gap-3 lg:gap-8 w-full place-content-center place-items-center">
        <h1 className="BTitr sm:mb-2 text-2xl xl:mb-10 xl:text-4xl text-[#011C4B]">
          ثبت نام
        </h1>
        <div className="flex flex-col lg:flex-row lg:flex-wrap gap-4 md:gap-3 xl:gap-8 w-full place-content-center place-items-center">
          {Inputs.map((input) => (
            <div className="flex flex-col place-content-center place-items-center mx-auto gap-1 IranianSans w-5/6 lg:w-[200px] xl:w-[250px] h-14 p-2 rounded-xl rounded-tr-none">
              <input
                key={input.id}
                id={input.id}
                {...input}
                {...register(input.id)}
                onChange={props.handleInputChange}
                className="rigesterInput IranianSans w-4/5 lg:w-[200px] xl:w-[250px] h-14 p-2 bg-[#fefefe] placeholder:text-[10px] lg:placeholder:text-sm xl:placeholder:text-base hover:border-[#011C4B] ease-in duration-500 focus:outline-none focus:ring-0 focus:rounded-xl focus:bg-[#fbfbfb] rounded-xl rounded-tr-none shadow-lg"
              />

              {errors[input.id] && (
                <p className="text-xs text-[#660708]">
                  {errors[input.id].message}
                </p>
              )}
            </div>
          ))}
        </div>

        <div className="w-full flex place-content-center place-items-center mt-4 ">
          <button
            type="submit"
            className={`BTitr w-5/6 h-12 lg:h-[50px] lg:text-xl text-white bg-[#011C4B] hover:bg-[#32569B] focus:ring-4 focus:ring-gray-300 font-medium rounded-2xl rounded-tr-none text-sm px-5 py-2.5 mx-auto mb-2 xl:my-4 `}
          >
            ثبت نام
          </button>
        </div>
      </div>
      <div className="flex flex-col md:gap-3 w-full place-content-center place-items-center xl:mt-6">
        <div className="flex gap-2 place-content-center place-items-center">
          <div className="w-[30px] sm:w-[50px] h-[1px] bg-[#8d99ae]"></div>
          <p className="text-[#8d99ae] text-[10px] sm:text-sm py-2">
            قبلا در سامانه کارآموزی ثبت نام کرده ای؟
          </p>
          <div className="w-[30px] sm:w-[50px] h-[1px] bg-[#8d99ae]"></div>
        </div>
        <div className="flex flex-col gap-1 place-content-center place-items-center BTitr font-bold text-xs sm:text-base">
          <Link to="/login">
            <p className="text-gray-700">ورود</p>
          </Link>
        </div>
      </div>
    </form>
  );
}
