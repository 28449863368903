import React from 'react'
import helper from './../../../assets/video/helper.mp4'
import { renderAboutPage } from '../../../Modules/renderAboutPage'
import helpIcon from './../../../assets/images/Pointers_duotone.png'

export default function HelpCompany() {
    const names=[
        'راهنمای شرکت ها'
    ]
  return (
    <>
    {renderAboutPage(names ,helpIcon ,'راهنمای شرکت ها' )}
    <div className='flex place-content-center place-items-center my-8'>
        <video className=" h-40 md:h-80 rounded-xl" controls>
        <source src={helper} type="video/mp4"/>
        </video>
    </div>
    </>

  )
}
