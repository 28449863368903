import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export default function LoginForm({
  userName,
  password,
  setuserName,
  setPassword,
  handleSubmit,
}) {
    
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  useEffect(() => {
    if (userName && userName.length !== 0 && password && password.length !== 0) {
        setIsButtonEnabled(true);
    }
  }, [userName, password]);
  
  return (
    <form onSubmit={handleSubmit} className="w-full" action="#">
      <div className="flex flex-col gap-3 md:gap-3 xl:gap-8 w-full place-content-center place-items-center">
        <h1 className="BTitr sm:mb-2 text-2xl xl:mb-10 xl:text-4xl text-[#011C4B]">ورود</h1>
        <input
          id="studentNum"
          name="studentNum"
          type="text"
          value={userName}
          onChange={(e) => setuserName(e.target.value)}
          className="IranianSans w-5/6 h-14 p-2 bg-[#fefefe] placeholder:text-[10px] lg:placeholder:text-sm hover:border-[#011C4B] ease-in duration-500 focus:outline-none focus:ring-0 focus:rounded-xl focus:bg-[#fbfbfb] rounded-xl rounded-tr-none shadow-lg"
          placeholder="شماره دانشجویی خود را وارد کنید"
        />
        <input
          id="password"
          name="password"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className="IranianSans w-5/6 h-14 p-2 bg-[#fefefe] placeholder:text-[10px] lg:placeholder:text-sm hover:border-[#011C4B] ease-in duration-500 focus:outline-none focus:ring-0 focus:rounded-xl focus:bg-[#fbfbfb] rounded-xl rounded-tr-none shadow-lg"
          placeholder="رمز عبور خود را وارد کنید"
        />
        <div className="w-full flex place-content-center place-items-center mt-1 sm:mt-4 ">
              <button
          disabled={!isButtonEnabled}
          type="submit"
          className={`BTitr w-5/6 h-12 lg:h-[50px] lg:text-xl text-white bg-[#011C4B] hover:bg-[#32569B] focus:ring-4 focus:ring-gray-300 font-medium rounded-2xl rounded-tr-none text-sm px-5 py-2.5 mx-auto mb-2 xl:my-4  ${
            !isButtonEnabled ? "cursor-not-allowed bg-[#011C4B]" : "bg-[#011C4B]"
          }`}
        >
          ورود
        </button>
        </div>
      </div>
      <div className="flex flex-col md:gap-3 w-full place-content-center place-items-center xl:mt-6">
        <div className="flex gap-2 place-content-center place-items-center">
          <div className="w-[50px] h-[1px] bg-[#8d99ae]"></div>
          <p className=" text-[#8d99ae] text-xs sm:text-base py-2">
            حساب کاربری ندارید؟
          </p>
          <div className="w-[50px] h-[1px] bg-[#8d99ae]"></div>
        </div>
        <div className="flex flex-col gap-1 place-content-center place-items-center BTitr font-bold text-xs sm:text-base">
          <Link to='/signup'>
            <p className="text-gray-700">ثبت نام</p>
          </Link>
          <Link to='/signup-supervisor'>
            <p className="text-gray-700">ثبت نام سرپرست شرکت</p>
          </Link>
        </div>
      </div>
    </form>
  );
}
