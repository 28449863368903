import React from "react";
import { Link } from "react-router-dom";
import laptop from "./../../../assets/images/laptop.png";
import MacBook from "./../../../assets/images/MacBook.png";
import rounded from "./../../../assets/images/rounded.png";

function HeaderRegister() {
  return (
    <div className="relative w-full h-[200px] md:h-full flex flex-col place-content-center place-items-center md:order-2 md:relative md:bg-[#011C4B] md:rounded-r-full">
      <img
        src={laptop}
        className="absolute top-[50%] right-[50%] translate-x-1/2 -translate-y-1/2 rounded-t-xl w-48 sm:w-72 md:hidden z-50"
        alt=""
      />
      <img
        src={MacBook}
        className="hidden absolute top-[50%] right-[40%] translate-x-1/2 -translate-y-1/2 rounded-t-xl w-11/12 md:flex"
        alt=""
      />
      <button className="absolute top-0 left-0 w-[80px] h-[40px] justify-center text-xs">
        <Link to="/">
          <div className="!text-[#fbfbfb] flex place-content-center place-items-center gap-2">
            <h1 className="text-xs BTitr">بازگشت</h1>
            <span className="font-bold">
              <i className="bi bi-arrow-left"></i>
            </span>
          </div>
        </Link>
      </button>
      <div className="absolute -bottom-1 -right-0 md:hidden">
        <img src={rounded} className="w-12 h-12" alt="" />
      </div>
    </div>
  );
}

export default HeaderRegister;
